<template>
  <ScanBarcodeFBMAdmin

  />
</template>

<script>
  import ScanBarcodeFBMAdmin from "./ScanBarcodeFBMAdmin/ScanBarcodeFBMAdmin";

  export default {
    name: "ScanBarcodeFBM",
    components: {
      ScanBarcodeFBMAdmin,
    },

    data() {
      return {
      }
    },

    methods: {

    }
  }
</script>

<style scoped>

</style>
